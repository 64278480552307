import Cookies from 'js-cookie';
import { LOCAL_STORAGE, COOKIE_STORAGE } from '/Foundation/React/code/Utilities/ExtranetConstants';

export function createMarkup(markup) {
  return { __html: markup };
}

export function formatAddress(addressObject) {
  let addressId = addressObject.AddressM3Id;
  if (addressId === '' || addressId === undefined) {
    addressId = addressObject.AddressTypeId;
  }

  let addressString = '';

  if (addressObject.AddressLine1 === '' || !addressObject.AddressLine1) {
    addressString =
      addressObject.AddressTitle +
      ' - ' +
      addressObject.AddressLine2 +
      ' - ' +
      addressObject.AddressLine3 +
      ' - ' +
      addressObject.State +
      ' | (' +
      addressId +
      ')';
  } else {
    addressString =
      addressObject.AddressTitle +
      ' - ' +
      addressObject.AddressLine1 +
      ' - ' +
      addressObject.Zip +
      ' - ' +
      addressObject.City +
      ' | (' +
      addressId +
      ')';
  }
  return addressString;
}

export function formatAddressExcludeTitle(addressObject) {
  let addressId = addressObject.AddressM3Id;
  if (addressId === '' || addressId === undefined) {
    addressId = addressObject.AddressTypeId;
  }

  let addressString = '';

  if (addressObject.AddressLine1 === '' || !addressObject.AddressLine1) {
    addressString =
      addressObject.AddressLine2 +
      ' - ' +
      addressObject.AddressLine3 +
      ' - ' +
      addressObject.State +
      ' | (' +
      addressId +
      ')';
  } else {
    addressString =
      addressObject.AddressLine1 + ' - ' + addressObject.Zip + ' - ' + addressObject.City + ' | (' + addressId + ')';
  }
  return addressString;
}

export const trimText = (text, charLength, screenWidth) => {
    if (screenWidth > 991) return text;
    if (text.length > charLength) {
        return text.substring(0, charLength).concat('...');
    }
    return text;
};

export function getParameterByName(name) {
  var match = RegExp('[?&]' + name + '=([^&]*)').exec(window.location.search);
  return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
}

export const logOut = function (e) {
  if (e !== undefined) {
    e.preventDefault();
  }

  var values = {
    event: 'secondaryNavigation',
    labelName: 'Log out',
  };
  try {
    google.tagmanager.add(values);
  } catch (error) {}
  fetch(`/api/sitecore/user/logout`, {
    method: 'GET',
  })
    .then((response) => response.json())
    .then((responseJson) => onSuccess(responseJson))
    .catch((error) => onError(error));

  function onSuccess(data) {
    let redirectLink = '/account/logout';
    let status = data.status;

    if (status === 200) {
      localStorage.removeItem(LOCAL_STORAGE.CART_IDENTIFIER);
      localStorage.removeItem(LOCAL_STORAGE.EXTRANET_USER);
      localStorage.removeItem(LOCAL_STORAGE.SHOW_RETAILER_MODAL);
      Cookies.remove(COOKIE_STORAGE.SELECTED_RETAILER_ID);
      Cookies.remove(COOKIE_STORAGE.SELECTED_ADDRESS_ID);
      window.location.href = redirectLink;
    } else {
      console.log(status);
    }
  }
  function onError(error) {
    console.log(error);
  }
};
