import React from 'react';

const FittingInstructionMovie = ({ component, index }) => {
  return (
    <>
      {component && component.IsYoutube && (
        <div className="instruction-item m-3 fittingInstructionMovie">
          <div className="video position-relative">
            <img src={component.ThumbnailUrl.replace('/default.jpg', '/maxresdefault.jpg')} />
            <button
              className="btn ytPlayBtn instruction-yt-button"
              id={`ytPlayBtn-instruction-${index}`}
              data-bs-toggle="modal"
              data-bs-target="#instructionVideoModal"
              data-src={
                component?.AssetURL.split('?')[0] + '?autoplay=1&mute=1&controls=1&rel=0&enablejsapi=1&playsinline=0'
              }
            >
              <span className="sr-only">Play video</span>
              <div className="btn ytPlayIcon">
                <i className="le-icon-play"></i>
              </div>
            </button>
          </div>
          <span className="heading6 m-2">{component.VideoTitle}</span>
        </div>
      )}
    </>
  );
};
export default FittingInstructionMovie;
