import { getSearchString, getPerCategorySearchString } from './SearchString';
import FitAssistantStatusEnum from '../FitAssistant/Enum/FitAssistantStatusEnum';
import FitAssistantStatusIconEnum from '../FitAssistant/Enum/FitAssistantStatusIconEnum';
import Cookies from 'js-cookie';
import { IsProductPage } from './IsProductPage';

import FittingInstructionMovie from './FittingInstructionMovie';
import React from 'react';
import { renderToString } from 'react-dom/server';
import { onFitAssistantSaveData } from '../FitAssistant/Util/Tracking';
import { initMobileSlider } from '../../ThuleGroup/ProductDetail';
import { getMercadoPaymentMessage } from '/Foundation/Common/code/Utils/PaymentUtils';
export const SetFitStatusForAllProducts = (fitAssistantStatus) => {
    let allProductCards = [...document.querySelectorAll('div[data-itemfilters]')].filter(
        (x) => x.dataset.productType !== 'Accessory'
    );
    allProductCards.forEach((card) => {
        let compatibilityStatus = card.querySelector('.compatibility-status');
        if (!compatibilityStatus) return;
        compatibilityStatus.classList.remove('d-none');
        let indicator = card.querySelector('.fit-indicator');
        ResetFitIndicator(indicator);
        indicator.classList.add(fitAssistantStatus);
    });
};

export const getIconClassByFitAssistantStatus = (fitAssistantStatus) => {
    switch (fitAssistantStatus) {
        case FitAssistantStatusEnum.NoSelection:
            return FitAssistantStatusIconEnum.NoSelection;
        case FitAssistantStatusEnum.Confirmed:
            return FitAssistantStatusIconEnum.Confirmed;
        case FitAssistantStatusEnum.NoFit:
            return FitAssistantStatusIconEnum.NoFit;
        case FitAssistantStatusEnum.RackMounting:
            return FitAssistantStatusIconEnum.Confirmed;
        default:
            return FitAssistantStatusIconEnum.Unconfirmed;
    }
};
export const ResetFitStatusForAllProducts = () => {
    let allProductCards = [...document.querySelectorAll('div[data-itemfilters]')];
    allProductCards.forEach((card) => {
        let compatibilityStatus = card.querySelector('.compatibility-status');
        if (!compatibilityStatus) return;
        let indicator = card.querySelector('.fit-indicator');
        ResetFitIndicator(indicator);
        compatibilityStatus.classList.add('d-none');

        const dbg__cartBtnContainer = card.querySelector('.dbg__cartBtnContainer');
        if (dbg__cartBtnContainer) dbg__cartBtnContainer.classList.add('d-none');

        //reset price
        if (window._THULEDATA?.isExtranet) {
            var rrpElement = card.querySelector('.RRP-value');
            if (rrpElement) rrpElement.innerHTML = rrpElement.dataset.defaultprice;
        } else {
            var priceElement = card.querySelector('.price-value');
            if (priceElement) priceElement.innerHTML = priceElement.dataset.defaultprice;

            var discountPriceElement = card.querySelector('.discount-price-value');
            if (discountPriceElement) discountPriceElement.innerHTML = discountPriceElement.dataset.defaultprice;
        }
        setSolutionProductListItemImage(card, null);
    });
    if (!IsProductPage && typeof resetIsotopeLayout != 'undefined') resetIsotopeLayout();
};

const ResetFitIndicator = (fitIndicatorElement) => {
    if (!fitIndicatorElement) return;
    fitIndicatorElement.classList.remove(
        FitAssistantStatusEnum.Confirmed,
        FitAssistantStatusEnum.Unconfirmed,
        FitAssistantStatusEnum.NoFit,
        FitAssistantStatusIconEnum.Confirmed,
        FitAssistantStatusIconEnum.Unconfirmed,
        FitAssistantStatusIconEnum.NoFit
    );
};
export const SetFitStatusForConfirmedFits = (uniqueProductCardConfirmedFits, noFitIds, initialData = null) => {
    let allProductCards = [...document.querySelectorAll('div[data-itemfilters]')];
    allProductCards.forEach((card) => {
        let compatibilityStatus = card.querySelector('.compatibility-status');
        if (!compatibilityStatus) return;
        compatibilityStatus.classList.remove('d-none');
        let indicator = card.querySelector('.fit-indicator');
        ResetFitIndicator(indicator);

        const dbg_addToCart = card.querySelector('.dbg__cartBtnContainer');
        if (uniqueProductCardConfirmedFits.includes(card)) {
            indicator.classList.add(FitAssistantStatusEnum.Confirmed, FitAssistantStatusIconEnum.Confirmed);
            if (
                dbg_addToCart &&
                dbg_addToCart.querySelector('.dbg_cart_btn') &&
                _THULEDATA?.FitAssistant &&
                _THULEDATA._buyersGuide
            )
                dbg_addToCart.classList.remove('d-none');
            card.dataset.sortFit = 1;
        } else if (card.dataset.productType !== 'Accessory') {
            const isConfirmedNofit = noFitIds && noFitIds.indexOf(card.dataset.pimid) > -1 || initialData?.DisableUnconfirmed;
            const iconClass = getIconClassByFitAssistantStatus(
                isConfirmedNofit ? FitAssistantStatusEnum.NoFit : FitAssistantStatusEnum.Unconfirmed
            );
            if (isConfirmedNofit) {
                indicator.classList.add('nofit', iconClass);
                card.dataset.sortFit = -1;
            } else {
                indicator.classList.add('unconfirmed', iconClass);
                card.dataset.sortFit = 0;
            }

            if (dbg_addToCart) dbg_addToCart.classList.add('d-none');
        }
    });
    triggerSortOnPLP();
    if (!IsProductPage && typeof resetIsotopeLayout != 'undefined') resetIsotopeLayout();
};

const triggerSortOnPLP = () => {
    const sortSection = document.querySelector('.sorting-section');
    if (!sortSection) return;

    const event = new CustomEvent('triggerSort', { detail: 'fit' });
    let count = 0;
    const triggerCheck = setInterval(() => {
        if (sortSection.hasAttribute('data-triggersort') || count >= 19) {
            sortSection.dispatchEvent(event);
            clearInterval(triggerCheck);
        }
        count++;
    }, 100);
};

export const UpdateFitAssistantPriceAndATCForPDP = (solutionPriceContainer, addToCartButton) => {
    setAddToCartButton(addToCartButton, true);
    setPricePDP(solutionPriceContainer);
    refreshPlacements(solutionPriceContainer);
};

export const UpdateFitAssistantPriceAndATCForPDPExtranet = (solutionComponents, priceContainer) => {
    if (!solutionComponents) return;

    var productBuyArea = document.querySelector('#productBuyArea');
    var priceElement = productBuyArea.querySelector('.price');
    var addToCartElement = productBuyArea.querySelector('.add-to-your-cart');
    var spinner = productBuyArea.querySelector('.loading-spinner');

    spinner?.classList.remove('d-none');
    priceElement?.classList.add('d-none');
    addToCartElement?.classList.add('d-none');

    import('../FitAssistant/Extranet/SolutionAddToCart')
        .then((module) => module.getSolutionPriceFromM3(solutionComponents, priceContainer))
        .then(() => {
            spinner.classList.add('d-none');
            priceElement.classList.remove('d-none');
            addToCartElement.classList.remove('d-none');
        });
};

export const UpdateFitAssistantPriceAndATC = (productIds) => {
    fetch('/api/sitecore/fitguide/getfitguideatcandprice', {
        method: 'POST',
        headers: new Headers({
            'Content-Type': 'application/json',
        }),
        body: JSON.stringify({ ProductIds: productIds }),
    })
        .then((resp) => resp.json())
        .then((data) => {
            setAddToCartButton(data.Button, true);
            setPricePDP(data.PriceContainer);
            refreshPlacements(data.PriceContainer);
        });
};

const setAddToCartBtnForDBG = (pli, matchingProduct) => {
    if (!pli || !matchingProduct || !_THULEDATA?._buyersGuide) return;
    var productIdsElement = pli.querySelector('.product-add-to-cart .product-ids-for-solution');
    if (productIdsElement) {
        productIdsElement.value = matchingProduct.ProductIds.join(',');
    }
};

const setAddToCartButton = (button, showSolutionAddToCartBtn) => {
    if (!button) return;

    // 7 = B2CDisabled, do now show any button at all.
    if (button.Status == 7) return;

    var addToCartArea = document.querySelector('.product-detail__buy-area .product-add-to-cart');
    if (addToCartArea) {
        let solutionIdsElement = addToCartArea.querySelector('.product-ids-for-solution');
        solutionIdsElement.value = button.ProductIdsForSolution;
        if (!showSolutionAddToCartBtn) return;

        if (_THULEDATA.FitGuideRacksData) {
            setAddToCartBtnForFitGuideRacks(button);
        } else {
            setSolutionAddToCartBtnVisibility(button);
        }
    }
};

const setAddToCartBtnForFitGuideRacks = (addToCartButton) => {
    if (!addToCartButton && !IsProductPage) return;
    const addToCartBtnElement = document.querySelector('.product-add-to-cart button:not(.d-none)');
    const status = document.querySelector('.product-add-to-cart .tpd-product-stock-status');
    if (!addToCartBtnElement || !status) return;

    addToCartBtnElement.innerHTML = addToCartButton.ButtonText;
    status.innerHTML = addToCartButton.ButtonSubText;
    if (!addToCartButton.IsInStock || !addToCartButton.IsSellable) {
        addToCartBtnElement.classList.remove('thule-btn-shop');
        addToCartBtnElement.classList.add('thule-btn-secondary');
        addToCartBtnElement.classList.add('disabled');
    } else {
        addToCartBtnElement.classList.add('thule-btn-shop');
        addToCartBtnElement.classList.remove('thule-btn-secondary');
        addToCartBtnElement.classList.remove('disabled');
    }
};

const setSolutionAddToCartBtnVisibility = (addToCartButton) => {
    if (!addToCartButton) return;
    var addToCartArea = document.querySelector('.product-detail__buy-area .product-add-to-cart');
    if (addToCartArea) {
        let buyableBtn = addToCartArea.querySelector('.solution-btn.buyable button');
        let notBuyableBtn = addToCartArea.querySelector('.solution-btn.not-buyable button');

        if (!buyableBtn || !notBuyableBtn) return;

        // Status: 0 = sellable, 1 = AvailableToPromiseLimitFulfilled, 2 = OutOfStock
        if (addToCartButton.Status <= 2) {
            addToCartArea.querySelector('.tpd-product-stock-status.single-product')?.classList.add('d-none');

            addToCartArea.querySelector('.buyable .tpd-product-stock-status span').textContent =
                addToCartButton.ButtonSubText;
            addToCartArea.querySelector('.not-buyable .tpd-product-stock-status span').textContent =
                addToCartButton.ButtonSubText;
        }

        if (
            addToCartButton.IsSellable &&
            (addToCartButton.IsInStock || (!addToCartButton.IsInStock && addToCartButton.IsAvailableToPromiseFulfilled))
        ) {
            buyableBtn.textContent = addToCartButton.ButtonText;
            buyableBtn.parentElement.classList.remove('d-none');
            notBuyableBtn.parentElement.classList.add('d-none');
        } else {
            notBuyableBtn.textContent = addToCartButton.ButtonText;
            notBuyableBtn.parentElement.classList.remove('d-none');
            buyableBtn.parentElement.classList.add('d-none');
        }
        //if making solution add to cart btn visible we hide the default btn to prevent duplicate btns
        const defaultAddToCartBtn = document.querySelector(
            '.product-detail__buy-area .product-add-to-cart > button:not(.roof-rack-btn, .d-none)'
        );
        if (defaultAddToCartBtn) defaultAddToCartBtn.classList.add('d-none');
        addToCartArea.querySelector('.atc-stockstatus').value = addToCartButton.StockStatusText;
    }
};
const setPricePDP = (priceContainer) => {
    if (!priceContainer) return;

    var priceArea = document.querySelector('.product-detail__buy-area .price');
    if (priceArea === null) return;
    var priceElement = priceArea.querySelector('.price-value');
    var discountPriceElement = priceArea.querySelector('.discount-price-value');

    priceArea.querySelector('.ga-price').innerHTML = priceContainer.Price;
    priceArea.querySelector('.ga-discount-price').innerHTML = priceContainer.DiscountPrice;

    if (window._THULEDATA?.isExtranet) {
        var rrpElement = priceArea.querySelector('.RRP-value');
        rrpElement.innerHTML = priceContainer.PriceFormatted;
        if (!window._THULEDATA?.FitAssistant?.IsRooftopTents) {
            priceElement.innerHTML = '';
        }
    } else {
        priceElement.innerHTML = priceContainer.PriceFormatted;
        setAddToCartButtonPrice(priceContainer);
        if (priceContainer.DiscountPrice > 0) {
            priceElement.classList.add('line-through-text');
            discountPriceElement.innerHTML = priceContainer.DiscountPriceFormatted;
        } else {
            priceElement.classList.remove('line-through-text');
            setMercadoMessage(priceContainer.Price);
            discountPriceElement.innerHTML = '';
        }
    }
};

const setAddToCartButtonPrice = (priceContainer) => {
    if (!priceContainer)
        return;

    var addToCartArea = document.querySelector('.product-detail__buy-area .product-add-to-cart');

    if (!addToCartArea)
        return;

    addToCartArea.querySelector('.atc-original-price').value = priceContainer.Price;
    addToCartArea.querySelector('.atc-original-price-formatted').value = priceContainer.PriceFormatted;

    if (priceContainer.DiscountPrice > 0) {
        addToCartArea.querySelector('.atc-product-price').value = priceContainer.DiscountPriceFormatted;
        addToCartArea.querySelector('.atc-product-price-nocurrency').value = priceContainer.DiscountPrice;
    } else {
        addToCartArea.querySelector('.atc-product-price').value = priceContainer.PriceFormatted;
        addToCartArea.querySelector('.atc-product-price-nocurrency').value = priceContainer.Price;
    }
}

const setMercadoMessage = (amount) => {
    const mercadoBaseURL = document.querySelector('#mercadoBaseURL')?.value;
    const mercadoAPIKey = document.querySelector('#mercadoAPIKey')?.value;
    if (mercadoBaseURL && mercadoAPIKey) getMercadoPaymentMessage(mercadoBaseURL, mercadoAPIKey, amount);
};

const setPricePLP = (productListItemElement, priceContainer) => {
    if (!priceContainer || !productListItemElement) return;

    const priceElement = productListItemElement.querySelector('.price-value');
    if (!priceElement) return;

    if (window._THULEDATA?.isExtranet) {
        var rrpElement = productListItemElement.querySelector('.RRP-value');
        rrpElement.innerHTML = priceContainer.PriceFormatted;
        priceElement.innerHTML = '';
    } else {
        var discountPriceElement = productListItemElement.querySelector('.discount-price-value');
        if (!discountPriceElement) return;

        if (priceContainer.DiscountPrice > 0) {
            priceElement.innerHTML = priceContainer.PriceFormatted;
            discountPriceElement.innerHTML = priceContainer.DiscountPriceFormatted;
            priceElement.classList.add('line-through-text');
        } else {
            priceElement.innerHTML = priceContainer.PriceFormatted;
            discountPriceElement.innerHTML = '';
            priceElement.classList.remove('line-through-text');
        }
    }
};

const refreshPlacements = (priceContainer) => {
    if (!priceContainer) return;
    if (document.getElementsByTagName('afterpay-placement')[0])
        document.getElementsByTagName('afterpay-placement')[0].setAttribute('data-amount', priceContainer.Price);
    if (document.getElementsByTagName('klarna-placement')[0])
        document
            .getElementsByTagName('klarna-placement')[0]
            .setAttribute('data-purchase-amount', Math.round(priceContainer.Price * 100));
    if (window.KlarnaOnsiteService) window.Klarna.OnsiteMessaging.refresh();
};

const setCookieData = (carInformationSelector, initialData) => {
    const selection = getSearchString(carInformationSelector);
    const perCategorySelection = getPerCategorySearchString(carInformationSelector);

    if (IsProductPage) {
        setQueryFromSearchParameters(initialData.FitAssistantCookieName, carInformationSelector);
    }
    Cookies.set(initialData.FitAssistantCookieName, selection, {
        expires: 365,
        path: `/${initialData.Language.toLowerCase()}/`,
        secure: true,
    });

    Cookies.set(initialData.FitAssistantPerCategoryCookieName, perCategorySelection, {
        expires: 365,
        path: window.location.pathname
            .split('/')
            .filter((x) => !x.includes('-_-'))
            .join('/'),
        secure: true,
    });
    onFitAssistantSaveData(carInformationSelector);
};

export const isConfirmedNoFit = (nofitIds, productId) => {
    if (!nofitIds) return false;
    return nofitIds.indexOf(productId) > -1;
};
export const setCompabilityVisually = (response, carInformationSelector, initialData, setCookie) => {
    if (!response || !response.Success) return FitAssistantStatusEnum.Unconfirmed;

    let fitStatus = FitAssistantStatusEnum.NoFit;

    if (response.SolutionComponents) {
        handleDocuments(response.SolutionComponents);
    }
    if (setCookie) {
        setCookieData(carInformationSelector, initialData);
    }
    let confirmedCards = [];

    if (IsProductPage) {
        let productIdsForSolutionElement = document.querySelector('.product-ids-for-solution');
        const productId = document.querySelector('.product-id')?.value ?? document.querySelector('#productBuyArea')?.dataset.productid;
        fitStatus = response.ProductPageMatch
            ? FitAssistantStatusEnum.Confirmed
            : isConfirmedNoFit(response.NoFitIds, productId)
                ? FitAssistantStatusEnum.NoFit
                : initialData.DisableUnconfirmed ? FitAssistantStatusEnum.NoFit : FitAssistantStatusEnum.Unconfirmed;

        //check if productid is in nofitids
        if (productIdsForSolutionElement) {
            let bundleIds = productIdsForSolutionElement.dataset.defaultids;

            for (let product of response.MatchingProducts) {
                if (bundleIds?.includes(product.PimId)) {
                    fitStatus = FitAssistantStatusEnum.Confirmed;
                    break;
                }
            }
        }

        if (window._THULEDATA?.isExtranet && !window._THULEDATA?.FitAssistant?.IsRooftopTents) {
            UpdateFitAssistantPriceAndATCForPDPExtranet(response.SolutionComponents, response.SolutionPriceContainer);
        } else {
            UpdateFitAssistantPriceAndATCForPDP(response.SolutionPriceContainer, response.AddToCartButton);
        }

        setImageBasedOnRoofRack(response);
    } else {
        const matchingPimIds = response.MatchingProducts.map((x) => {
            return x.PimId;
        });
        const cards = document.querySelectorAll('div.isotope-item[data-pimid]');
        const allPimIds = [...cards].map((x) => x.getAttribute('data-pimid'));
        cards.forEach((node) => {
            const pimid = node.getAttribute('data-pimid');
            let matchingProduct = null;
            if (pimid && matchingPimIds.indexOf(pimid) > -1) {
                confirmedCards.push(node);
                matchingProduct = response.MatchingProducts.find((x) => x.PimId === pimid);
                setPricePLP(node, matchingProduct?.SolutionPriceContainer);
                setAddToCartBtnForDBG(node, matchingProduct);
            }
            else if (!IsProductPage && window._THULEDATA?.FitAssistant?.IsCarSeats) {
                const bundleIds = node.getAttribute('data-bundle-ids');
                if (!bundleIds) return;

                let bundleIdArray = bundleIds.split(',');
                let intersection = bundleIdArray.filter(bun => matchingPimIds.includes(bun));

                if (intersection.length > 0) confirmedCards.push(node);
            }
            setSolutionProductListItemImage(node, matchingProduct);
        });
        if (confirmedCards.length > 0) {
            fitStatus = FitAssistantStatusEnum.Confirmed;
        } else if (confirmedCards.length === 0 && (response.NoFitIds.length === 0 || !allPimIds.every(x => response.NoFitIds.includes(x))) && !initialData.DisableUnconfirmed) {
            fitStatus = FitAssistantStatusEnum.Unconfirmed;
        }
        SetFitStatusForConfirmedFits(confirmedCards, response.NoFitIds, initialData);
    }
    return fitStatus;
};

export const setSolutionProductListItemImage = (pli, matchingProduct) => {
    if (!pli) return;
    const pimid = pli.getAttribute('data-pimid');
    if (!pimid) return;
    const images = pli.querySelectorAll(`.img-container img[data-pimid='${pimid}'][data-defaultimage]`);
    images.forEach((image) => {
        const defaultUrl = image.getAttribute('data-defaultimage');
        if (matchingProduct && matchingProduct.ImageUrl) {
            image.setAttribute('src', matchingProduct.ImageUrl);
        } else if (defaultUrl) {
            image.setAttribute('src', defaultUrl);
        }
    });
};

export const setQueryFromSearchParameters = (cookieName, parameters) => {
    const url = new URL(document.location);
    const setSearchParameters = parameters
        .map((item) => `${item.parameterName}:${item.value}|${encodeURI(item.text)}`)
        .join(',');
    url.searchParams.set(cookieName, setSearchParameters);
    history.replaceState(null, null, url.toString());
};

export const getSearchParametersFromQuery = (cookieName) => {
    const regexS = '[\\?&]' + cookieName + '=([^&#]*)';
    const regex = new RegExp(regexS);
    const results = regex.exec(window.location.search);
    if (results === null) {
        return null;
    } else {
        return decodeURIComponent(results[1].replace(/\+/g, ' '));
    }
};

export const removeQueryParameters = () => {
    const url = new URL(document.location);
    window.history.replaceState({}, '', `${url.pathname}`);
};

export const handleDocuments = (solutionComponents) => {
    const documentContainerElement = document.querySelector('#documentsContainer');
    const instructionMovieContainer = document.getElementById('image-navigation');
    if (!documentContainerElement || !solutionComponents) return;
    documentContainerElement.innerHTML = '';
    if (instructionMovieContainer) {
        instructionMovieContainer.querySelectorAll('.fittingInstructionMovie').forEach((movie) => {
            movie.remove();
        });
    }
    let assetIds = [];
    solutionComponents.forEach((c) => {
        if (c.Informationdoc?.length > 0) {
            addInstructions(c.Informationdoc, c.InformationDocumentsLinkText, assetIds);
        }

        if (c.FittingInstruction?.length > 0) {
            addInstructions(c.FittingInstruction, c.FittingInstructionLinkText, assetIds);
        }

        if (c.CompatibilityDocuments?.length > 0) {
            addInstructions(c.CompatibilityDocuments, c.CompatibilityDocumentsLinkText, assetIds);
        }

        if (c.FittingInstructionMovies?.length > 0) {
            addFittingInstructionMovies(c, instructionMovieContainer);
        }
    });
};

const addFittingInstructionMovies = (component, instructionMovieContainer) => {
    if (component?.FittingInstructionMovies && instructionMovieContainer) {
        component.FittingInstructionMovies.forEach((movie, index) => {
            const html = renderToString(<FittingInstructionMovie component={movie} index={index} />);
            instructionMovieContainer.innerHTML += html;
        });
    }

    const instructionMovies = document.querySelectorAll('.instruction-yt-button');
    const iframe = document.querySelector('#instructionVideoModal iframe');

    instructionMovies.forEach((element) => {
        element.addEventListener('click', () => {
            if (!iframe) return;
            iframe.src = element.dataset.src;
        });
    });
};

const addInstructions = (instructionsList, linkText, assetIds) => {
    const documentContainerElement = document.querySelector('#documentsContainer');
    if (!documentContainerElement) return;
    instructionsList.forEach((d) => {
        if (assetIds.indexOf(d.AssetId) === -1) {
            assetIds.push(d.AssetId);
            const icon = d.IconCssClass ? `<i class="${d.IconCssClass}"></i>` : '';
            documentContainerElement.innerHTML += `<li class="downloadable-asset">${icon} <a target="_blank" class="tag-manager-user-manual paragraph--s text-decoration-underline" href="${d.AssetURL}" data-assettype="${d.FileEnding}">${linkText}</a></li>`;
        }
    });
};

const setImageBasedOnRoofRack = (response) => {
    if (!response?.MatchingProducts || response.MatchingProducts.length === 0) return;

    const footGeneration = response.MatchingProducts[0].SolutionFootGeneration;
    const footType = response.MatchingProducts[0].SolutionFootType;

    let containerDiv = document.querySelector('.pdp__mainImages[data-isroofrack="True"]');
    if (!containerDiv || !footGeneration || !footType) return;
    let imageWrappers = containerDiv.querySelectorAll('.pdp__mainImageWrapper');
    imageWrappers.forEach((imageWrapper) => {
        if (imageWrapper.dataset.generation === footGeneration && imageWrapper.dataset.foottype === footType) {
            imageWrapper.classList.remove('d-none');
            imageWrapper.classList.add('splide__slide');
        } else {
            imageWrapper.classList.add('d-none');
            imageWrapper.classList.remove('splide__slide');
        }
    });
    initMobileSlider();
};

export const getAdditionalFilterForOptions = (step, additionalFilters) => {
    if (!additionalFilters || !step.FilterOptions) return null;
    return JSON.stringify(additionalFilters);
};

export const getAdditionalFilterForProductSearch = (step, additionalFilters) => {
    if (!additionalFilters || !step.FilterProductResult) return null;
    return JSON.stringify(additionalFilters);
};
