export const getSearchString = (data) =>
  data
    .filter((item) => ['make', 'model', 'year'].includes(item.parameterName.toLowerCase()))
    .map((item) => `${item.parameterName.toLowerCase()}:${item.value}|${encodeURI(item.text)}`)
    .join(',');

export const getPerCategorySearchString = (data) =>
  data
    .filter((item) => !['make', 'model', 'year'].includes(item.parameterName.toLowerCase()))
    .map((item) => `${item.parameterName.toLowerCase()}:${item.value}|${encodeURI(item.text)}`)
    .join(',');
