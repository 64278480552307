import React, { useState, useRef, useEffect } from 'react';

import { isIOS } from '../../Helpers/UiHelper';
import './Select.less';

const Select = ({
  id,
  label,
  options,
  placeholder,
  value,
  onBlur,
  onChange,
  hasError,
  errorMessage,
  isDisabled,
  additionalClasses,
  selectClassName,
}) => {
  const canUseScrollIntoViewIfNeeded = typeof document.querySelector('body').scrollIntoViewIfNeeded === 'function';

  const [showSelect, _setShowSelect] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const myStateRef = useRef(showSelect);
  const setShowSelect = (data) => {
    myStateRef.current = data;
    _setShowSelect(data);
  };

  const selectRef = useRef(null);
  const containerRef = useRef(null);
  const optionsRef = useRef(null);

  useEffect(() => {
    let body = document.querySelector('body');
    body.addEventListener('mousedown', (e) => {
      if (e.target.closest('.thule-select')?.id !== containerRef?.current?.id) {
        if (myStateRef.current) onBlur();
        setShowSelect(false);
      }
    });
  }, []);

  useEffect(() => {
    let matchingOption = options.find((x) => x.value === selectRef.current.value);
    let index = options.indexOf(matchingOption);

    if (index >= 0) {
      setSelectedIndex(index + 1);
      if (canUseScrollIntoViewIfNeeded) {
        optionsRef.current?.children[index].scrollIntoViewIfNeeded();
      } else {
        optionsRef.current?.children[index].scrollIntoView();
      }
    }
  }, [selectRef.current?.value]);

  useEffect(() => {
    optionsRef.current.style.height =
      document.querySelector('.layout__content').getBoundingClientRect().bottom -
      selectRef.current.getBoundingClientRect().bottom -
      4 +
      'px';
  }, [showSelect, optionsRef, selectRef]);

  const handleKeyDown = (e) => {
    if (e.key === 'Escape') {
      e.preventDefault();
      if (showSelect) onBlur();
      setShowSelect(false);
    } else if (e.key === 'Enter') {
      e.preventDefault();
      scrollTo(0, e.target.getBoundingClientRect().y - 50);
      if (showSelect) {
        onBlur();
        setShowSelect(false);
      } else {
        setShowSelect(true);
      }
    }
    if (e.key === ' ') {
      e.preventDefault();
      scrollTo(0, e.target.getBoundingClientRect().y - 50);
      setShowSelect(true);
    }
  };

  const handleOptionMouseDown = (index) => {
    selectRef.current.options.selectedIndex = index;
    setSelectedIndex(index);
    triggerOnChange(selectRef.current);
    setShowSelect(false);
    onBlur();
  };

  const triggerOnChange = (element) => {
    element.dispatchEvent(new Event('change', { bubbles: true }));
  };

  const handleMouseDown = (e) => {
    e.preventDefault();
    if (e.button !== 0) return;

    e.target.focus();
    if (isIOS()) e.target.blur(); // Fix for iOS. Required to hide native dropdown
    if (_THULEDATA._Checkout) scrollTo(0, e.target.getBoundingClientRect().y - 50);
    if (showSelect) {
      onBlur();
      setShowSelect(false);
    } else {
      setShowSelect(true);
    }
  };

  return (
    <div
      className={`thule-select ${isDisabled ? 'disabled' : ''} ${hasError ? 'error' : ''} ${additionalClasses || ''}`}
      ref={containerRef}
      id={`${id}_selectContainer`}
    >
      <label htmlFor={id}>{label}</label>
      <div className="position-relative paragraph">
        <select
          required
          id={id}
          ref={selectRef}
          onMouseDown={handleMouseDown}
          onKeyDown={handleKeyDown}
          onChange={onChange}
          disabled={isDisabled}
          value={value}
          aria-controls={`${id}_options`}
          className={selectClassName || ''}
        >
          <option disabled hidden value="">
            {placeholder}
          </option>
          {options &&
            options.map((option) => {
              return (
                <option value={option.value} key={option.value}>
                  {option.name}
                </option>
              );
            })}
        </select>
        <i className={`trailing-icon le-icon-chevron ${showSelect ? 'rot-90' : 'rot-270'}`}></i>
        <div
          className={`options position-absolute w-100 ${showSelect ? '' : 'd-none'}`}
          id={`${id}_options`}
          role="listbox"
          aria-expanded={showSelect ? false : true}
          ref={optionsRef}
        >
          {options.map((option, index) => {
            return (
              <div
                className={`option ${selectedIndex == index + 1 ? 'selected' : ''}`}
                data-value={option.value}
                onMouseDown={() => handleOptionMouseDown(index + 1)}
                key={option.value}
                role="option"
                aria-selected={selectedIndex == index + 1 ? true : false}
              >
                <div>{option.name}</div>
              </div>
            );
          })}
        </div>
      </div>
      <div className={`supporting-text paragraph--xs error-message ${!hasError ? 'd-none' : ''}`}>{errorMessage}</div>
    </div>
  );
};

export default Select;
