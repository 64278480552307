import FitAssistantStepEnum from '../Enum/FitAssistantStepEnum';
export const onFitAssistantOpen = (carInformation) => {
  if (typeof google !== 'undefined')
    google.tagmanager.add({
      event: 'FA_Open',
      ...getGTMCarInformation(carInformation),
    });
};

export const onFitAssistantSaveData = (carInformation) => {
  if (typeof google !== 'undefined')
    google.tagmanager.add({
      event: 'FA_Save',
      ...getGTMCarInformation(carInformation),
    });
};

const getGTMCarInformation = (carInformation) => {
  const brand = carInformation.find((x) => x.parameterName === FitAssistantStepEnum.Makes)?.text;
  const year = carInformation.find((x) => x.parameterName === FitAssistantStepEnum.Years)?.text;
  const model = carInformation.find((x) => x.parameterName === FitAssistantStepEnum.Models)?.text;
  const variation = carInformation.find((x) => x.parameterName === FitAssistantStepEnum.Variations)?.text;

  return {
    brand: brand,
    year: year,
    model: model,
    variation: variation,
    selectedFilter: `${brand} ${model} ${year} | ${variation}`,
    url: window.location.href?.split('?')[0],
  };
};
