const isLoggedIn = () => {
  return window._THULEDATA._Checkout.isLoggedIn;
};

const isGuest = () => {
  return window.localStorage.isGuest && JSON.parse(window.localStorage.isGuest);
};

const setIsGuest = (value) => {
  window.localStorage.isGuest = value;
};

const showCityInputFirst = (country) => {
  return ['United States', 'Canada', 'United Kingdom'].includes(country);
};

const showStateField = (states) => {
  return states.length > 1;
};

export const isIOS = () => {
  let platform = navigator?.userAgent || navigator?.platform || 'unknown';

  return (
    /iPhone|iPod|iPad|iPad Simulator|iPhone Simulator|iPod Simulator/.test(platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  );
};

export { isGuest, setIsGuest, isLoggedIn, showCityInputFirst, showStateField };
