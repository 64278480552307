const FitAssistantStepEnum = Object.freeze({
  Mounting: 'mounting',
  RackMounting: 'rackmounting',
  Makes: 'make',
  Models: 'model',
  Years: 'year',
  Variations: 'variation',
  OtherFits: 'otherfits',
  NoFits: 'nofits',
  GenericRoofRacks: 'genericroofracks',
  RoofRacks: 'roofracks',
  VanSize: 'vansize',
  Unconfirmed: 'unconfirmed',
  NoRackFit: 'norackfit',
  RackNotCompatible: 'racknotcompatible',
  EnsureThirdParty: 'ensurethirdparty',
  CarSeatFit: 'carseatfit',
});

export default FitAssistantStepEnum;
