import React, { useState } from "react";

const SUCCESS = 0;
const ERROR = 1;
const WARNING = 2;

export function Message ({type, content}) {
  const [close, setClose] = useState(false);
  let cName = 'alert-message mb-4'; 
  switch (type) {
    case SUCCESS:
      cName += ' success';
      break;
    case ERROR:
      cName += ' error';
      break;
    case WARNING:
      cName += ' warning';
      break;
    default:
      break;
  }
 
  if (close) cName += ' fadeout';

  return (
    <div className={cName}>
      <div dangerouslySetInnerHTML={{ __html: content }} />
      <div className="msg-close" onClick={() => setClose(true)}>
        <i className="fas fa-times" />
      </div>
    </div>
  );
};