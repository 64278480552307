import React, { useState } from 'react';
import './Input.less';

const Input = React.forwardRef(
  (
    {
      id,
      type,
      placeholder,
      hasError,
      errorMessage,
      isDisabled,
      leadingIcon,
      trailingIcon,
      label,
      supportingText,
      maxLength,
      name,
      onBlur,
      onChange,
      onKeyUp,
      value,
      forgotPasswordLink,
      forgotPasswordText,
      additionalClasses,
      clearInput,
      autocomplete,
      defaultValue,
      onKeyDown,
      inputClassName,
    },
    ref
  ) => {
    const [showPassword, setShowPassword] = useState(false);

    return (
      <div
        className={`thule-input ${isDisabled ? 'disabled' : ''} ${hasError ? 'error' : ''} ${additionalClasses || ''}`}
      >
        {label && <label htmlFor={id}>{label}</label>}
        <div className="position-relative">
          {leadingIcon && <i className={`leading-icon le-icon-${leadingIcon}`}></i>}
          <input
            type={type === 'password' && showPassword ? 'text' : type}
            placeholder={`${type === 'password' ? '********' : placeholder ? placeholder : ''}`}
            className={`input ${leadingIcon ? 'ps-9' : ''} ${trailingIcon ? 'pe-9' : ''} ${inputClassName || ''}`}
            id={id}
            disabled={isDisabled}
            maxLength={maxLength}
            name={name}
            onBlur={onBlur}
            onChange={onChange}
            onKeyUp={onKeyUp}
            value={value}
            autoComplete={autocomplete}
            defaultValue={defaultValue}
            onKeyDown={onKeyDown}
            ref={ref}
          />
          {trailingIcon && !hasError && <i className={`trailing-icon le-icon-${trailingIcon}`}></i>}

          {clearInput && !hasError && value && (
            <button className="clear-button trailing-icon btn p-0" onClick={clearInput}>
              <span className="sr-only">Clear input</span>
              <i className=" le-icon-x"></i>
            </button>
          )}

          {type === 'password' && !hasError && (
            <i
              className={`trailing-icon ${showPassword ? 'le-icon-eye-off' : 'le-icon-eye'}`}
              onClick={() => setShowPassword(!showPassword)}
            ></i>
          )}
          {hasError && <i className={`trailing-icon le-icon-warning error`}></i>}
        </div>
        {supportingText && !hasError && (
          <div className="supporting-text paragraph--xs" dangerouslySetInnerHTML={{ __html: supportingText }}></div>
        )}
        {hasError && (
          <div
            className="supporting-text paragraph--xs error-message"
            dangerouslySetInnerHTML={{ __html: errorMessage }}
          ></div>
        )}
        {type === 'password' && (
          <span className="d-flex justify-content-end mt-3">
            <a href={forgotPasswordLink} className="forgot-password-link paragraph--xs">
              {forgotPasswordText}
            </a>
          </span>
        )}
      </div>
    );
  }
);

export default Input;
